import { Field, Form, Formik, useFormikContext } from "formik";
import { useContext } from "react";
import { Outlet, useLoaderData, useNavigate } from "react-router-dom";

import { Button, Card, Heading, addToast } from "@icg360/design-system";

import { BaseLayout } from "components/base-layout";
import { Body } from "components/common/body";
import { AuthAppContext } from "components/root/auth-app-provider.js";
import { type PropertyProfileLoaderData } from "components/root/router";
import {
  RadioButtonField,
  RadioButtonGroup,
} from "components/shared/form-fields";
import { MainLayout } from "components/shared/main-layout";
import { ProgressBar } from "components/shared/progress-bar";
import { PropertyProfileSidebar } from "components/shared/property-profile-sidebar";
import { PROPERTY_ALARM_UPDATE_TYPE } from "consts";
import {
  useSendPropertyProfileUpdateNotificationMutation,
  useUpdatePolicyIntervalDataMutation,
  useUserPropertyProfileDataQuery,
} from "gql/__generated__/hooks.js";
import { formatDate, logException, trackEvent, useFlags } from "utils";

import { usePropertyProfilePage } from "../hooks";
import styles from "./update-security.module.scss";

export type SecurityFormValues = {
  burglarAlarm: string;
  fireAlarm: string;
};

export const UpdateSecurity = () => {
  const navigate = useNavigate();
  const { retireProxy } = useFlags();
  const loaderData = useLoaderData() as PropertyProfileLoaderData;
  const { selectedPolicyId, userInfo, userDetails } =
    useContext(AuthAppContext);
  const { data: propertyProfileData } = useUserPropertyProfileDataQuery({
    variables: {
      policyID: selectedPolicyId,
    },
  });
  const { setPropertyUpdated } = usePropertyProfilePage(
    retireProxy ? loaderData?.propertyProfile : propertyProfileData
  );

  const effectiveDate = userDetails?.currentTerm?.effectiveDatePolicyTerm;

  const [sendPropertyProfileUpdateNotification] =
    useSendPropertyProfileUpdateNotificationMutation();
  const [updatePolicyIntervalData] = useUpdatePolicyIntervalDataMutation();

  const handleSubmit = async (
    { burglarAlarm, fireAlarm },
    { setStatus, setSubmitting }
  ) => {
    try {
      setSubmitting(true);
      trackEvent("Property security - Updates submitted", {
        burglarAlarm,
        fireAlarm,
      });

      const endorsementUpdates = {
        fireAlarm: PROPERTY_ALARM_UPDATE_TYPE[fireAlarm],
        burglarAlarm: PROPERTY_ALARM_UPDATE_TYPE[burglarAlarm],
      };

      const { data } = await sendPropertyProfileUpdateNotification({
        variables: {
          policyID: selectedPolicyId,
          emailAddress: userInfo?.email,
          username: userInfo?.email,
          endorsementUpdates,
        },
        context: {
          clientName: "keystone-api",
        },
      });

      if (fireAlarm !== "Central alarm" || burglarAlarm !== "Central alarm") {
        const autoEndorsementUpdates = {
          fireAlarm:
            fireAlarm === "Central alarm"
              ? undefined
              : PROPERTY_ALARM_UPDATE_TYPE[fireAlarm],
          burglarAlarm:
            burglarAlarm === "Central alarm"
              ? undefined
              : PROPERTY_ALARM_UPDATE_TYPE[burglarAlarm],
        };

        await updatePolicyIntervalData({
          variables: {
            policyID: selectedPolicyId,
            emailAddress: userInfo?.email,
            username: userInfo?.email,
            endorsementUpdates: autoEndorsementUpdates,
          },
          context: {
            clientName: "keystone-api",
          },
        });
      }

      if (data) {
        trackEvent("Property security - Update completed");
        setPropertyUpdated(true);
        const hasFutureEffectiveDate = new Date(effectiveDate) > new Date();
        const toastMessage = hasFutureEffectiveDate
          ? `We have recorded your changes. They will be reflected on the platform after your policy gets renewed on ${formatDate(
              effectiveDate,
              "MM/DD/yyyy"
            )}.`
          : "We have received your update! Once validated, you will see these changes reflected here on your property profile.";
        addToast(toastMessage, { icon: true, duration: 10000 });
        navigate("/my/property");
      }
    } catch (error) {
      logException(error);
      setStatus({ type: "error", message: error.message });
      trackEvent("Property security - Update submission error", {
        errorCode: error.code,
        errorMessage: error.message,
      });
    }

    setSubmitting(false);
  };

  return (
    <BaseLayout hideNavigation>
      <Formik
        initialValues={{ burglarAlarm: "", fireAlarm: "" }}
        onSubmit={handleSubmit}
      >
        <Outlet />
      </Formik>
    </BaseLayout>
  );
};

export const UpdateSecurityForm = () => {
  const {
    setFieldValue,
    values: { burglarAlarm, fireAlarm },
  } = useFormikContext<SecurityFormValues>();
  const navigate = useNavigate();

  return (
    <>
      <ProgressBar progress={50} />
      <MainLayout
        sidebar={
          <PropertyProfileSidebar
            headline="Documentation"
            body={
              <p>
                If you have installed a new <b>central alarm system</b>, you’ll
                receive an email after this update requesting your{" "}
                <b>alarm certificate</b> to validate this change.
              </p>
            }
          />
        }
      >
        <Card className={styles.updateSecurity}>
          <Heading size="lg">Please update your security details.</Heading>
          <br />
          <Form>
            <Body>Do you have a burglar alarm system?</Body>
            <Field
              name="burglarAlarm"
              aria-label="Burglar alarm selections"
              component={RadioButtonGroup}
              onChange={(value) => {
                setFieldValue("burglarAlarm", value);
              }}
              value={burglarAlarm}
              fieldChildren={() => (
                <>
                  <Field value="None" label="No" component={RadioButtonField} />
                  <Field
                    value="Local alarm"
                    label="Yes, a local alarm"
                    component={RadioButtonField}
                  />
                  <Field
                    value="Central alarm"
                    label="Yes, a central alarm"
                    component={RadioButtonField}
                  />
                </>
              )}
            />

            <Body>Do you have a fire alarm system?</Body>
            <Field
              name="fireAlarm"
              aria-label="Fire alarm selections"
              component={RadioButtonGroup}
              onChange={(value) => {
                setFieldValue("fireAlarm", value);
              }}
              value={fireAlarm}
              fieldChildren={() => (
                <>
                  <Field value="None" label="No" component={RadioButtonField} />
                  <Field
                    value="Local alarm"
                    label="Yes, a local alarm"
                    component={RadioButtonField}
                  />
                  <Field
                    value="Central alarm"
                    label="Yes, a central alarm"
                    component={RadioButtonField}
                  />
                </>
              )}
            />
          </Form>
          <div className={styles.navigation}>
            <Button
              appearance="tertiary"
              onPress={() => navigate("/my/property")}
            >
              Go back
            </Button>
            <Button
              appearance="primary"
              size="default"
              onPress={() => navigate("/my/property/security/review")}
              disabled={!fireAlarm || !burglarAlarm}
              data-testid="security-continue-btn"
            >
              Continue
            </Button>
          </div>
        </Card>
      </MainLayout>
    </>
  );
};
